import { useCallback } from 'react';

import { useTracking } from '@change/core/react/tracking';

import { useDecisionMakerRecommenderModal } from './useDecisionMakerRecommenderModal';

type DecisionMakerRecommenderModal = ReturnType<typeof useDecisionMakerRecommenderModal>;
type Options = Parameters<DecisionMakerRecommenderModal>[0] & { onClose?: () => void };

export function useDecisionMakerRecommender(): (options: Options) => Promise<void> {
	const openModal = useDecisionMakerRecommenderModal();
	const track = useTracking();

	return useCallback(
		async ({ onClose, ...options }) => {
			await openModal(options).waitForClosed();
			void track('dm_modal_close', { context: options.trackingContext });
			onClose?.();
		},
		[openModal, track],
	);
}
