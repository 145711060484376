import type { UtilityContext } from '@change/core/react/utilityContext';

import type { UpdateDecisionMakerContactMutation, UpdateDecisionMakerContactMutationVariables } from './api.graphql';
import { UpdateDecisionMakerContact } from './api.graphql';

export type UpdateDMContactInput = UpdateDecisionMakerContactMutationVariables['input'];

export type UpdateDMContactResult = { success: boolean };

export async function updateDMContactApi(
	{
		input,
	}: {
		input: UpdateDMContactInput;
	},
	{ gql: { fetch } }: UtilityContext,
): Promise<UpdateDMContactResult> {
	try {
		const { data } = await fetch<UpdateDecisionMakerContactMutation, UpdateDecisionMakerContactMutationVariables>({
			query: UpdateDecisionMakerContact,
			variables: {
				input,
			},
		});
		if (data?.updateDecisionMakerContact?.status === 'SUCCESS') return { success: true };
		return { success: false };
	} catch (e) {
		return { success: false };
	}
}
