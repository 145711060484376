/* eslint-disable max-lines-per-function */
import type { ComponentProps } from 'react';
import { useReducer } from 'react';

import { flushSync } from 'react-dom';
import { useLocation } from 'react-router';

import { useNavigate } from '@change/core/react/router';
import { useTracking } from '@change/core/react/tracking';

import type { RecommendedDecisionMakerList } from '../../index';

import { ADD_CLICK_TRACKING_KEY, REMOVE_CLICK_TRACKING_KEY } from './constants';
import { recommendedDecisionMakerListReducer } from './reducer';
import { useAddRecommendedDecisionMakers } from './useAddRecommendedDecisionMakers';
import { ERROR_MAP } from './useAddRecommendedDecisionMakers/constants';
import { useInitialState } from './useInitialState';
import { useRemoveRecommendedDecisionMakers } from './useRemoveRecommendedDecisionMakers';

type RecommendedDecisionMaker = ComponentProps<
	typeof RecommendedDecisionMakerList
>['recommendedDecisionMakers'][number];

type UseRecommendedDecisionMakerListReturn = ModelHookResult<
	{
		recommendedDecisionMakerListState: Parameters<typeof recommendedDecisionMakerListReducer>[0];
	},
	{
		addRecommendedDecisionMaker: (decisionMaker: RecommendedDecisionMaker) => Promise<void>;
		removeRecommendedDecisionMaker: (decisionMaker: RecommendedDecisionMaker) => Promise<void>;
	}
>;

export function useRecommendedDecisionMakerList(
	recommendedDecisionMakerList: RecommendedDecisionMaker[],
	petitionId: string,
	trackingContext: string,
): UseRecommendedDecisionMakerListReturn {
	const track = useTracking();
	const navigate = useNavigate();
	const { pathname, search } = useLocation();

	const initialState = useInitialState(recommendedDecisionMakerList);
	const [state, dispatch] = useReducer(recommendedDecisionMakerListReducer, initialState);

	const { mutate: addDecisionMaker } = useAddRecommendedDecisionMakers({
		onSuccess: ({ decisionMaker }, { recommendedDecisionMaker }) => {
			flushSync(() => {
				dispatch({
					type: 'ADD_DECISION_MAKER_SUCCESS',
					payload: {
						...recommendedDecisionMaker,
						canonicalPetitionTargetId: decisionMaker.id,
					},
				});
			});
		},
		onError: (error, { recommendedDecisionMaker }) => {
			if (error === ERROR_MAP.UNAUTHORIZED) {
				const params = encodeURIComponent(`${pathname}${search}`);
				navigate(`/login_or_join/redirected?redirectTo=${params}`);
			}
			flushSync(() => {
				dispatch({ type: 'ADD_DECISION_MAKER_ERROR', payload: recommendedDecisionMaker });
			});
		},
	});

	const { mutate: removeDecisionMaker } = useRemoveRecommendedDecisionMakers({
		onSuccess: (_, { recommendedDecisionMaker }) => {
			flushSync(() => {
				dispatch({ type: 'REMOVE_DECISION_MAKER_SUCCESS', payload: recommendedDecisionMaker });
			});
		},
		onError: (error, { recommendedDecisionMaker }) => {
			if (error === ERROR_MAP.UNAUTHORIZED) {
				const params = encodeURIComponent(`${pathname}${search}`);
				navigate(`/login_or_join/redirected?redirectTo=${params}`);
			}
			flushSync(() => {
				dispatch({ type: 'REMOVE_DECISION_MAKER_ERROR', payload: recommendedDecisionMaker });
			});
		},
	});

	const trackDecisionMakerClick = (trackingName: string, decisionMaker: RecommendedDecisionMaker): void => {
		void track(trackingName, {
			dm_name: decisionMaker.name,
			dm_id: decisionMaker.canonicalPetitionTargetId || undefined,
			dm_role: decisionMaker.title || undefined,
			context: trackingContext,
			petition_id: petitionId,
		});
	};

	const addRecommendedDecisionMaker = async (decisionMaker: RecommendedDecisionMaker): Promise<void> => {
		trackDecisionMakerClick(ADD_CLICK_TRACKING_KEY, decisionMaker);
		dispatch({ type: 'ADD_DECISION_MAKER_LOADING', payload: decisionMaker });

		await addDecisionMaker(petitionId, decisionMaker, trackingContext);
	};

	const removeRecommendedDecisionMaker = async (decisionMaker: RecommendedDecisionMaker): Promise<void> => {
		trackDecisionMakerClick(REMOVE_CLICK_TRACKING_KEY, decisionMaker);
		await removeDecisionMaker(petitionId, decisionMaker, trackingContext);
	};

	return {
		data: { recommendedDecisionMakerListState: state },
		actions: {
			addRecommendedDecisionMaker,
			removeRecommendedDecisionMaker,
		},
	};
}
