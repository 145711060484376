import { useQuery } from '@change/core/react/async';
import type { QueryHookResult } from '@change/core/react/async';
import { useUtilityContext } from '@change/core/react/utilityContext';

import { useCountryCode } from 'src/app/shared/hooks/l10n';

import { searchDecisionMakers } from './api/searchDecisionMakers';
import type { DecisionMaker } from './types';

export function useDecisionMakerSearch(input: string): QueryHookResult<{ data: readonly DecisionMaker[] }> {
	const countryCode = useCountryCode();
	const utilityContext = useUtilityContext();
	return useQuery(
		async () => ({
			data: await searchDecisionMakers({ searchQuery: input, countryCode }, utilityContext),
		}),
		[utilityContext, input, countryCode],
		{ loadingBetweenQueries: true },
	);
}
