import type { UtilityContext } from '@change/core/react/utilityContext';

import type { DecisionMakerContactQuery, DecisionMakerContactQueryVariables } from './api.graphql';
import { DecisionMakerContact } from './api.graphql';

export async function getDMContactApi(decisionMakerId: string, utilityContext: UtilityContext): Promise<string> {
	const {
		gql: { fetch },
	} = utilityContext;

	const { data } = await fetch<DecisionMakerContactQuery, DecisionMakerContactQueryVariables>({
		query: DecisionMakerContact,
		variables: {
			decisionMakerId,
			type: 'email',
		},
	});

	return data?.decisionMakerContactByDmIdAndType?.value || '';
}
