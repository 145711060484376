import type { UtilityContext } from '@change/core/react/utilityContext';

import type { DecisionMaker } from '../types';

import type { SearchDecisionMakerQuery, SearchDecisionMakerQueryVariables } from './searchDecisionMaker.graphql';
import { SearchDecisionMaker } from './searchDecisionMaker.graphql';

type DecisionMakerParams = {
	searchQuery: string;
	countryCode: string;
};

export async function searchDecisionMakers(
	{ searchQuery, countryCode }: DecisionMakerParams,
	{ gql: { fetch } }: UtilityContext,
): Promise<readonly DecisionMaker[]> {
	if (searchQuery.trim() === '') return [];

	const { data } = await fetch<SearchDecisionMakerQuery, SearchDecisionMakerQueryVariables>({
		query: SearchDecisionMaker,
		variables: {
			searchQuery,
			countryCode,
		},
		rejectOnError: true,
	});

	if (!data?.decisionMakersByCriteria) {
		throw new Error('Unexpected Query Error on Decision Maker Search');
	}

	if (data.decisionMakersByCriteria.__typename === 'SearchDecisionMakersErrorResponse') {
		const message = data.decisionMakersByCriteria.status;
		throw new Error(`Error in searchDecisionMakers: ${message}`);
	}

	return [
		...data.decisionMakersByCriteria.items,
		// Adding this unconfirmed dummy DM to trigger the "Add DM" functionality
		{ id: '0', displayName: searchQuery.trim(), isPerson: null, isConfirmed: false, photo: null, summary: '' },
	];
}
